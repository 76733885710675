<template>
  <div class="lost">
    <div class="niki-is-lost mb-20 px-5 flex flex-col">
      <div class="top flex flex-row justify-between items-end">
        <img
          class="bg-404 relative z-10 -mb-4"
          src="/404.png"
        />
      </div>
      <div class="body">
        <img src="/niki-404-breathe.gif" />
      </div>
      <div class="footer flex justify-center">
        <button
          class="s-registration-register-btn mt-10"
          @click="handleError"
        >
          <span class="s-registration-register-btn-span">Follow Me Back Home</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
  import { clearError } from '#imports'

  const props = defineProps({
    error: Object
  })

  const handleError = () => clearError({ redirect: '/' })
</script>

<style scoped>
  .lost {
    background-color: #002d52;
    @apply h-screen w-screen flex flex-col justify-center items-center relative;
    .niki-is-lost {
      .bg-404 {
        @apply w-80;
      }
    }
    .s-registration-register-btn {
      background: #fe84b8;
      color: #fff;
      @apply font-alt;
      font-size: 14px;
      height: 48px;
      width: 232px;
      position: relative;

      &::after,
      &::before {
        content: '';
        width: 0;
        height: 2px;
        position: absolute;
        transition: all 0.2s linear;
        background: #da568f;
      }

      &::after {
        bottom: 0;
        right: 0;
      }

      &::before {
        top: 0;
        left: 0;
      }

      &:hover {
        &::after,
        &::before {
          width: 100%;
        }
      }
    }

    .s-registration-register-btn-span {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      width: 100%;

      &::after,
      &::before {
        content: '';
        width: 0;
        height: 2px;
        position: absolute;
        transition: all 0.2s linear;
        background: #da568f;
      }

      &::after {
        left: 0;
        top: 0;
      }

      &::before {
        right: 0;
        bottom: 0;
      }

      &:hover {
        &::before,
        &::after {
          width: 2px;
          height: 100%;
        }
      }
    }
  }
</style>
